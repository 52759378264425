






































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {State} from 'vuex-class'

import {VForm, VIcon, VLayout, VTextField} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'
import Utils from '@/utils'
import moment from 'moment'
import {AppConfigState} from '@/store/modules/configuration'
import GradientCard from '@/components/GradientCard.vue'
import ColorCard from '@/components/ColorCard.vue'
import {getTrueBgColor} from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {
    ColorCard,
    GradientCard,
    VForm,
    VLayout,
    VIcon,
    VTextField,
    ValidationProvider,
    InputDatePicker: Utils.loadComponent('proxy/Inputs/InputDatePicker'),
  },
  name: 'DateRangeField',
})
export default class DateRangeField extends Vue {
  @State('configuration') public configState!: AppConfigState
  @State((state) => state.profile.language) public lang!: string

  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | object

  @Prop({
    type: Boolean,
    default: false,
  }) public required!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public disabled!: boolean
  @Prop({
    type: String,
    required: false,
  }) public name?: string
  @Prop({
    type: String,
    required: false,
  }) public description?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public unstyled?: boolean

  @Prop({
    type: Object,
    default: () => null,
  }) public readonly value!: { start: number, end: number } | null

  public innerValue: { start: number | null, end: number | null } = {start: null, end: null}
  // TODO: refactor to have innerValue as YYYY-MM-DD string and remove innerValueUnpacked
  public innerValueUnpacked: { start: null | string; end: null | string } = {start: null, end: null}

  public today: string = moment().format('YYYY-MM-DD')

  public bgColor: string | null = null

  @Watch('innerValue', {deep: true})
  public onInnerValueChange(val: { start: number | null, end: number | null } | null) {
    this.$emit('input', val)
  }

  @Watch('value', {deep: true})
  public onValueChange(val: { start: number, end: number } | null) {
    if (val) {
      if (val.start !== this.innerValue.start || val.end !== this.innerValue.end) {
        this.setInner(val.start, val.end)
      }
    } else {
      this.setInner(null, null)
    }
  }

  public get min() {
    const start = this.today
    let end = start
    if (this.innerValue.start) {
      end = moment(this.innerValue.start, 'X').format('YYYY-MM-DD')
    }
    return {start, end}
  }

  protected setInner(start: number | null, end: number | null) {
    if (!!start || !!end) {
      this.$set(this, 'innerValue', {start, end})
    } else {
      this.$set(this, 'innerValue', {start: null, end: null})
    }
    this.setUnpacked(start, end)
  }

  protected setUnpacked(start: number | null, end: number | null) {
    const formatted = (t: number | null) => {
      return t ? moment(t, 'X').format('YYYY-MM-DD') : null
    }
    this.$set(this.innerValueUnpacked, 'start', formatted(start))
    this.$set(this.innerValueUnpacked, 'end', formatted(end))
  }

  protected pack(x: 'start' | 'end', date: string) {
    const m = moment(date, 'YYYY-MM-DD')
    const timestamp = m.unix()
    const inner: { start: number | null, end: number | null } = JSON.parse(JSON.stringify(this.innerValue))

    if (x === 'start') {
      inner.start = timestamp
    } else {
      inner.end = timestamp
    }

    if (inner.start && inner.end && inner.start > inner.end) {
      if (x === 'start') {
        this.setInner(inner.start, null)
      } else {
        this.setInner(null, null)
      }
      return
    }

    this.setInner(inner.start, inner.end)
  }

  protected setCheckInDate(date: string) {
    this.pack('start', date)
  }

  protected setCheckOutDate(date: string) {
    this.pack('end', date)
  }

  protected created() {
    if (this.value) {
      this.setInner(this.value.start, this.value.end)
    }
  }

  protected mounted() {
    this.bgColor = getTrueBgColor(this.$parent.$el)
  }
}
